<template>
  <div>

    <div class="col-span-1 flex items-center flex-wrap mb-5">
      <h1 class="text-2xl font-bold mr-3">All Lenders</h1>
      <router-link :to="{ name: 'lenders-new'}" class="text-sm text-gray-700">+ Add new lender</router-link>
    </div>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      
      <datatable 
        :data="lenders.data"
        :columns="lenders.columns" 
        :actions="lenders.actions" 
        :loading="lenders.loading" 
        :query="lenders.query" 
        :dropdown="false"
        :onClick="click"
        ref="table"
      />

    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deletedModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        lender deleted successfully
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.deletedModal.close">
        Okay
      </button>
      
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deletedConfirmationModal">

      <img :src="assets.icons.lg.exclamation" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Delete this lender?
      </div>

      <template v-if="getFormError(deleteForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(deleteForm) }}</span>
        </div>
      </template>

      <div class="text-center">
        <button type="button" class="btn btn-blue mr-3" @click.prevent="deleteLender(lender)" :disabled="deleteForm.loading || updateForm.loading">
          <span v-if="deleteForm.loading">Deleting</span>
          <span v-else>Okay</span>
        </button>
        
        <button type="button" class="btn btn-red" @click.prevent="$refs.deletedConfirmationModal.close">
          Cancel
        </button>
      </div>
      
    </modal>

  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    data() {
      return {
        lender: null,
        lenders: this.$options.resource([], {
          actions: [
            {
              text: 'delete',
              class: 'border border-red-500 text-red-500 rounded-sm px-4 py-2',
              action: this.confirmDelete
            }
          ],
          columns: [
            {
              name: 'name',
              th: 'Name'
            },
            {
              name: 'created_at',
              th: 'Date Added',
              render: (lender, date) => moment(date).fromNow()
            }
          ],
          loading: false,
          query: '',
        }),
        deleteForm: this.$options.basicForm([]),
        updateForm: this.$options.basicForm([
          'agent_code'
        ]),
      }
    },
    beforeMount() {
      this.getLenders();
    },
    methods: {
      click(lender) {
        this.lender = lender;
        this.updateForm.data.agent_code.value = lender.agent_code || '';
        this.$refs.modal.open();
      },
      confirmDelete(lender) {
        this.lender = lender;
        this.$refs.deletedConfirmationModal.open();
      },
      async deleteLender(lender) {
        this.deleteForm.loading = true;
        await this.sendRequest('admin.lenders.delete', lender.id, {
          success: () => {
            this.$refs.deletedModal?.open();
            this.getLenders();
          },
          error: error => {
            this.deleteForm.error = error;
          }
        });
        this.deleteForm.loading = false;
      },
      async getLenders() {
        this.lenders.loading = true;
        await this.sendRequest('admin.lenders.all', {
          success: (response) => {
            this.lenders.data = response.data.lenders;
          }
        });
        this.lenders.loading = false;
      }
    }
  }
</script>